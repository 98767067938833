<template>
  <div class="watch">
    <div class="watch-header">
      <div
        v-for="(item,index) in tabNavList"
        :key="index"
        class="header-btn"
        :class="{'header-btn':true,active:activeNav===item.name}"
        @click=" handelNavClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-loading="loading" class="echarts-box">
      <div class="echarts-container">
        <LineEcharts :chart-data="cpuData" class="cpu" :x-axis-data="lineXAxisData" name="cpu使用率" color="#722ED1" />
      </div>
      <div class="echarts-container">
        <LineEcharts :chart-data="diskData" class="disk" :x-axis-data="lineXAxisData" name="硬盘使用率" color="#3469FF" />
      </div>
      <div class="echarts-container">
        <LineEcharts :chart-data="memoryData" class="memory" :x-axis-data="lineXAxisData" name="内存使用率" color="#FF7D00" />
      </div>
      <div class="echarts-container">
        <BarEcharts :chart-data="barData" :x-axis-data="barXAxisData" />
      </div>
    </div>
  </div>
</template>

<script>
import BarEcharts from '@/components/WatchEcharts/BarEcharts.vue'
import LineEcharts from '@/components/WatchEcharts/LineEcharts.vue'
import { getCpuUsage, getDiskUsage, getMemoryUsage, getNetRecv } from '@/api/bareMetal'
export default {
  components: {
    BarEcharts,
    LineEcharts
  },
  props: {
    systemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      spacetime: 60,
      step: 300,
      netStep: '1h',
      lineXAxisData: [],
      cpuData: [],
      diskData: [],
      memoryData: [],
      tabNavList: [
        {
          name: '实时',
          spacetime: 60,
          step: 300,
          netStep: '1h'
        },
        {
          name: '近一小时',
          spacetime: 60,
          step: 300,
          netStep: '1h'
        },
        {
          name: '近一天',
          spacetime: 3600,
          step: 1800,
          netStep: '24h'
        },
        {
          name: '近七天',
          spacetime: 3600 * 24 * 7,
          step: 3600 * 12,
          netStep: '168h'
        }
      ],
      activeNav: '实时',

      loading: false,
      barXAxisData: [],
      barData: {},
      interval: null
    }
  },
  mounted() {
    this.getData()
    this.interval = setInterval(() => {
      this.getData()
    }, 1000 * 60)
  },
  beforeUnmount() {
    if (this.interval) { clearInterval(this.interval) }
  },
  methods: {
    timestampToTime(timestamp) {
      timestamp = timestamp || null
      const date = new Date(timestamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      // const Y = date.getFullYear() + '-'
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      // const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return M + D + h + m
    },
    getData() {
      const promises = []
      const params = {
        spacetime: this.spacetime,
        step: this.step,
        systemIDs: this.systemId
      }
      // cpu
      promises.push(getCpuUsage(params))
      // 硬盘
      promises.push(getDiskUsage(params))
      // 内存
      promises.push(getMemoryUsage(params))
      const netParams = {
        step: this.netStep,
        systemIDs: this.systemId
      }
      // 网络接收速度
      promises.push(getNetRecv(netParams))
      const xAxisData = []
      const cpuData = []
      const diskData = []
      const memoryData = []
      // this.loading = true
      Promise.allSettled(promises).then(res => {
        // this.loading = false
        res.forEach((item, index) => {
          if (item.status === 'fulfilled' && item.value.meta.status === 200 && item.value.data.length > 0) {
            if (index !== 3) {
              const data = item.value.data[0]
              data.values.forEach((i, j) => {
                const time = this.timestampToTime(i[0])
                index === 1 ? xAxisData.push(time) : ''
                const value = Number(i[1]).toFixed(2)
                if (index === 0) {
                  cpuData.push(value)
                } else if (index === 1) {
                  diskData.push(value)
                } else if (index === 2) {
                  memoryData.push(value)
                }
              })
            } else {
              const barXAxisData = []
              const barData = {
                recvData: [],
                sentData: []
              }
              item.value.data.forEach(i => {
                barXAxisData.push(i.networkName)
                barData.recvData.push(Number(i.netRateDTO.netBytesRecv).toFixed(2))
                barData.sentData.push(Number(i.netRateDTO.netBytesSent).toFixed(2))
              })
              this.barXAxisData = barXAxisData
              this.barData = barData
            }
          }
        })
        this.lineXAxisData = xAxisData
        this.cpuData = cpuData
        this.diskData = diskData
        this.memoryData = memoryData
      })
    },
    handelNavClick(item) {
      this.activeNav = item.name
      this.spacetime = item.spacetime
      this.step = item.step
      this.netStep = item.netStep
      this.getData()
    }
  }
}
</script>

<style scoped lang="less">
.watch{
  font-family: PingFang SC, PingFang SC;
  background-color: #fff;
  // height: 100%;
  padding:16px;
  .watch-header{
       display: flex;
       align-items: center;
       height: 40px;
      .header-btn{
        box-sizing:border-box;
        font-weight: 500;
        font-size: 14px;
        color: #606266;
        border: 1px solid #f27a22;
        border-left: none;
        // height: 100%;
        padding:9px 16px;
        cursor: pointer;
        &:nth-child(1){
          border-left: 1px solid #f27a22;
          border-radius:3px 0 0 3px;
        }
        &:nth-last-child(1){
          border-radius:0 3px 3px 0;
        }
      }
      .active{
        // background-color: rgba($color: #FFA869, $alpha: 0.16);
         color: #FFA869;
      }
  }
  .echarts-box{
    // display: grid;
    // grid-template-columns: repeat(2, minmax(774px,50%) );
    // grid-template-rows:repeat(2,minmax(282px,1fr));
    // grid-gap: 16px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 16px;
    .echarts-container{
      width:calc(50% - 16px);
      // width: 774px;
      height: 222px;
      border: 1px solid rgba(0,0,0,0.1);
    }
  }

}
</style>
