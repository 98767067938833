<template>
  <div class="host">
    <div
      class="host-info info-box"
    >
      <div class="top-info">
        <div class="host-state top-info-item">
          <div class="i-right-box">
            <svg-icon class="i-right-svg" icon-class="host-state" />
          </div>
          <div class="i-right-content">
            <div class="i-title">主机状态</div>
            <div class="i-state">
              {{ detailInfo.statusName }}
            </div>
          </div>
        </div>
        <div class="cpu top-info-item">
          <div class="i-right-box">
            <svg-icon class="i-right-svg" icon-class="cpu" />
          </div>
          <div class="i-right-content">
            <div class="i-title">
              <div style="padding-right:5px;">CPU</div>
              <div class="i-title">{{ detailInfo.architecture }}</div>
            </div>
            <div class="i-state">
              {{ detailInfo.cpuCount }}核，{{ detailInfo.cpuSpeed }}Ghz
            </div>
            <div class="i-state i-model">{{ detailInfo.cpuModel }}</div>
          </div>
        </div>
        <div class="ram top-info-item">
          <div class="i-right-box">
            <svg-icon class="i-right-svg" icon-class="memory" />
          </div>
          <div class="i-right-content">
            <div class="i-title">
              <div>内存</div>
            </div>
            <div class="i-state">{{ detailInfo.memory/1024 }}GB</div>
          </div>
        </div>
        <div class="disk top-info-item">
          <div class="i-right-box">
            <svg-icon class="i-right-svg" icon-class="storage" />
          </div>
          <div class="i-right-content">
            <div class="i-title">
              <div>磁盘</div>
            </div>
            <div class="i-state">{{ detailInfo.storage/1024 }}GB</div>
          </div>
        </div>
      </div>
      <div class="bottom-info">
        <div class="left-sys-info">
          <div class="s-title">系统信息</div>
          <div class="s-info-item">
            <div class="s-i-title">制造商</div>
            <div v-if="detailInfo.hardwareInfo" class="s-i-val">{{ detailInfo.hardwareInfo.systemVendor }}</div>
          </div>
          <div class="s-info-item">
            <div class="s-i-title">产品型号</div>
            <div v-if="detailInfo.hardwareInfo" class="s-i-val">{{ detailInfo.hardwareInfo.systemProduct }}</div>
          </div>
          <div class="s-info-item">
            <div class="s-i-title">序列号</div>
            <div v-if="detailInfo.hardwareInfo" class="s-i-val">{{ detailInfo.hardwareInfo.systemSerial }}</div>
          </div>
          <div class="s-info-item">
            <div class="s-i-title">主板型号</div>
            <div v-if="detailInfo.hardwareInfo" class="s-i-val">{{ detailInfo.hardwareInfo.mainboardProduct }}</div>
          </div>
          <div class="s-info-item">
            <div class="s-i-title">固件版本</div>
            <div v-if="detailInfo.hardwareInfo" class="s-i-val">
              {{ detailInfo.hardwareInfo.mainboardFirmwareVersion }}
            </div>
          </div>
          <div class="s-info-item">
            <div class="s-i-title">固件日期</div>
            <div v-if="detailInfo.hardwareInfo" class="s-i-val">
              {{ detailInfo.hardwareInfo.mainboardFirmwareDate }}
            </div>
          </div>
        </div>
        <div class="right-network-info">
          <div class="n-title">网络信息</div>
          <div class="devops-table">
            <el-table
              ref="table"
              :header-cell-style="{
                background: '#F4F7FE',
                color: '#303133',
                height: '64px',
                fontSize: '16px'
              }"
              :data="hostTableData"
              style="width: 100%"
            >
              <el-table-column prop="name" label="网卡名" />
              <el-table-column prop="macAddress" label="MAC地址" />
              <el-table-column prop="linkSpeed" label="链接速度">
                <template #default="{ row }">
                  {{ row.linkSpeed }} Gbps
                </template>
              </el-table-column>
              <el-table-column prop="vlan" label="DHCP">
                <template #default="{ row }">
                  <div v-if="row.vlan">
                    {{ row.vlan.dhcpOn === true ? '无' : '托管' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="enabled" label="状态">
                <template #default="{ row }">
                  {{ row.enabled === true ? '启用' : '禁用' }}
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hostTableData: [],
      loading: false,
      detailInfo: {}
    }
  },
  watch: {
    info: {
      handler(val) {
        this.detailInfo = val
        this.hostTableData = val.interfaceSet
        // this.hostTableData = val.networks
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    console.log('detailInfo', this.detailInfo)
  },
  methods: {
    toNet() {
      this.$emit('toNet')
      // this.$router.push({ path: '/network' })
    }
  }
}
</script>

<style lang="less" scoped>
.host{
  .host-info {
      .top-info {
        height: 140px;
        // background: #ffffff;
        // border-radius: 16px;
        display: flex;
        /*align-items: center;*/
        .top-info-item {
          background: #ffffff;
          width: 25%;
          padding: 0 24px;
          display: flex;
          align-items: center;
          border-radius: 16px;
          margin-right: 16px;
          .i-right-box{
            .i-right-svg{
              width: 80px;
              height: 80px;
              margin-right: 25px;
            }
            // background: #F4F7FE;
            // border-radius: 20px 20px 20px 20px;
            // margin-right: 25px;
          }
          .i-right-svg {
            // font-size: 54px;
            // margin: 0 16px 0 20px;
          }
          .i-right-content {
            .i-title {
              /*line-height: 25px;*/
              display: flex;
              justify-content: space-between;
              // padding: 8px 20px 7px 0;
              padding-bottom: 16px;
              font-size: 16px;
              color: #909399;
            }
            .svg-icon {
              position: relative;
              bottom: 1px;
              font-size: 15px;
            }
            .i-state {
              font-size: 18px;
              color: #303133;
              font-weight: bold;
            }
            .i-model{
              padding-top: 16px;
            }
          }
        }
        .top-info-item:last-child{
            margin-right: 0;
          }
        .cpu {
          // width: 616px;
          .i-title {
            padding-bottom: 10px !important;
          }
        }
        .ram,
        .disk {
          .i-title {
            padding-bottom: 10px !important;
          }
        }
      }
      .bottom-info {
        margin-top: 16px;
        display: flex;
        .left-sys-info {
          width: 500px;
          padding: 22px 32px;
          margin-right: 16px;
          background: #ffffff;
          border-radius: 16px;
          .s-title {
            color: #303133;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 16px;
            border-bottom: 1px solid #E7E7E7;
          }
          .s-info-item {
            display: flex;
            margin-top: 16px;
            font-weight: 400;
            font-size: 16px;
            color: #303133;
            padding-left: 20px;
            .s-i-title {
              width: 70px;
              font-size: 16px;
              color: #909399;
              text-align: right;
              margin-right: 40px;
            }
          }
          .s-info-item:last-child{
            margin-bottom: 16px;
          }
        }
        .right-network-info {
          width: calc(100% - 516px);
          padding: 22px 32px;
          background: #fff;
          border-radius: 16px;
          .n-title {
            color: #303133;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 22px;
            border-bottom: 1px solid #E7E7E7;
            margin-bottom: 16px;
          }
          .n-tip {
            padding-top: 60px;
            cursor: pointer;
            font-size: 16px;
            color: #909399;
          }
        }
      }
    }
}
</style>
