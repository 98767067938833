<template>
  <div v-loading="isDetailLoading" class="detail">
    <div class="server-base-info">
      <div class="left-info">
        <div class="server-name">{{ detailInfo.hostname }}</div>

        <div class="v-line" />
        <div
          :class="
            detailInfo.powerState === 'off'
              ? 'anomaly'
              : detailInfo.powerState === 2
                ? 'warning'
                : 'end'
          "
        >
          <div class="big-circle">
            <div class="small-circle" />
          </div>
        </div>
      </div>
      <div>
        <span
          style="cursor: pointer"
          :class="
            detailInfo.powerState === 'off'
              ? 'anomaly-text'
              : detailInfo.powerState === 2
                ? 'warning-text'
                : 'end-text'
          "
        >
          {{
            detailInfo.powerState === "off"
              ? "关机"
              : detailInfo.powerState === 2
                ? "未知"
                : "开机"
          }}<i class="el-icon-arrow-down el-icon--right" />
        </span>
      </div>
    </div>
    <div class="detail-info">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="主机" name="first">
          <Host :info="detailInfo" @toNet="toNet" />
        </el-tab-pane>
        <el-tab-pane label="监控" name="watch" lazy>
          <Watch :system-id="systemId" />
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
import Host from '@/components/Host'
import Watch from '@/components/Watch/index.vue'
import { liberateMachine, getMachineDetail } from '@/api/bareMetal'
export default {
  components: {
    Host,
    Watch
  },
  props: {
    systemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isDetailLoading: false,
      detailInfo: {},
      powerState: 0,
      maasState: 0,
      deploySysForm: {
        distroSeries: '',
        userData: '',
        component: []
      },
      deploySysRules: {},
      deploySysBtnLoading: false,
      deploySysVisible: false,
      releaseServerVisible: false,
      releaseServerBtnLoading: false,
      releaseServerForm: {
        erase: false,
        quickErase: false,
        secureErase: false
      },
      releaseServerRules: {},
      activeName: 'first',
      hostTabLoading: false,
      hostTableData: []
    }
  },
  watch: {
    systemId(val) {
      if (val) {
        this.getDetail()
      }
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    // tabs
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 获取主机详情
    getDetail() {
      this.isDetailLoading = true
      getMachineDetail(this.systemId).then((res) => {
        const data = res.data
        this.detailInfo = data
        // this.hostTableData = data.interfaceSet.slice(0, 4)
        this.isDetailLoading = false
        console.log(this.detailInfo)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail {
  .server-base-info {
    margin-bottom: 16px;
    height: 80px;
    padding: 0 36px;
    border-bottom: 1px solid #e3e8f0;
    background: #fff;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    .left-info {
      display: flex;
      align-items: center;
      /*float: left;*/
      .server-name {
        color: #303133;
        font-size: 24px;
        font-weight: bold;
        padding-right: 30px;
      }
      .server-state {
        .el-dropdown-link {
          font-size: 14px;
          .el-icon-arrow-down {
            color: #cbcdd2;
          }
        }
      }
    }
    .anomaly {
      .big-circle {
        background-color: #ffdede;
      }
      .small-circle {
        background-color: #ff2323;
      }
    }
    .warning {
      .big-circle {
        background-color: #feebde;
      }
      .small-circle {
        background-color: #fd8e3f;
      }
    }
    .end {
      .big-circle {
        background-color: #d7fbef;
      }
      .small-circle {
        background-color: #15a675;
      }
    }
    .normal-text {
      color: #1677ff;
    }
    .anomaly-text {
      color: #ff2323;
    }
    .warning-text {
      color: #fd8e3f;
    }
    .end-text {
      color: #15a675;
    }
    .big-circle {
      width: 14px;
      height: 14px;
      border-radius: 100px;
      position: relative;
    }
    .small-circle {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }
  .detail-info {
    ::v-deep {
      .el-tabs__header {
        background-color: #fff;
      }
      .el-tabs__item.is-active {
        color: #165dff;
        border-bottom: 2px solid #165dff;
      }
      .el-tabs__item:focus.is-active.is-focus:not(:active) {
        box-shadow: none;
      }
      .el-tabs__item {
        color: #606266;
        font-size: 16px;
        font-weight: 400;
        height: 50px;
        font-size: 16px;
        font-weight: bold;
        line-height: 50px;
        padding: 0 16px;
      }
      .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
      .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
      .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
      .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding-left: 16px;
      }
      .el-tabs__active-bar {
        height: 0;
      }
    }
  }
}
</style>
