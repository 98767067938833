<template>
  <div class="card-view instance">
    <div class="detail-dialog">
      <el-dialog
        v-model="detailVisible"
        title="实例详情"
        :before-close="handleCloseDetail"
        width="1400px"
        :close-on-click-modal="false"
      >
        <!-- v-if确保每次打开弹窗时都重新渲染 -->
        <BareMetalDetail v-if="detailVisible" :system-id="systemId" />
      </el-dialog>
    </div>
    <!-- 端口映射 -->
    <div>
      <el-dialog
        v-model="portMapVisible"
        title="添加端口映射"
        :before-close="handleClosePortMap"
        width="410px"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <el-form-item label="名称">
            <el-input v-model="form.hostname" disabled />
          </el-form-item>
          <el-form-item label="IP">
            <el-input v-model="form.ip" disabled />
          </el-form-item>
          <el-form-item label="端口" prop="port">
            <el-input v-model="form.port" placeholder="请输入端口号" />
          </el-form-item>
          <el-form-item label="协议" prop="protocol">
            <el-select v-model="form.protocol" placeholder="请选择协议">
              <el-option
                v-for="item in form.protocolOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="handleClosePortMap">取消</el-button>
          <el-button type="primary" :loading="submitLoading" @click="handleAddPortMapSubmit">提交</el-button>

        </div>
      </el-dialog>
    </div>
    <!-- 重置密码 -->
    <div class="reset-password">
      <el-dialog
        v-model="resetPasswordVisible"
        title="重置密码"
        :before-close="handleCloseResetPassword"
        width="410px"
        :close-on-click-modal="false"
      >
        <el-form ref="resetPasswordForm" :model="resetPasswordForm" :rules="rules" label-width="120px">
          <el-form-item label="账户" label-width="7em">
            <el-input v-model="resetPasswordForm.account" disabled />
          </el-form-item>
          <el-form-item prop="password" label="新密码" label-width="7em">
            <el-input v-model="resetPasswordForm.password" show-password placeholder="请输入新密码" />
          </el-form-item>
          <el-form-item prop="confirmPassword" label="确认密码" label-width="7em">
            <el-input v-model="resetPasswordForm.confirmPassword" show-password placeholder="请再次输入新密码" />
          </el-form-item>
          <el-form-item prop="phoneCode" class="phone-code-btn" label="手机验证码" label-width="7em">
            <el-input v-model="resetPasswordForm.phoneCode" placeholder="手机验证码">
              <template #suffix>
                <el-button type="text" size="small" :disabled="isDisabledPsd" @click.prevent="sendMsgPsdHandle">
                  {{ buttonNamePsd }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="handleCloseResetPassword">取消</el-button>
          <el-button type="primary" :loading="submitLoading" @click="handleResetPasswordSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 释放实例 -->
    <div class="release-instance">
      <el-dialog
        v-model="releaseVisible"
        title="释放实例"
        :before-close="handleCloseRelease"
        width="410px"
        :close-on-click-modal="false"
      >
        <el-form ref="releaseForm" :model="releaseForm" :rules="rules" label-width="120px">
          <el-form-item prop="machineName" label="实例名称" label-width="7em">
            <el-input v-model="releaseForm.machineName" placeholder="请输入释放的实例名称" />
          </el-form-item>
          <el-form-item prop="isRelease" label="强制释放" label-width="7em">
            <el-switch v-model="releaseForm.isRelease" />
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="handleCloseRelease">取消</el-button>
          <el-button type="primary" :loading="submitLoading" @click="handleReleaseSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- ssh账号登录 -->
    <div class="ssh-login">
      <el-dialog
        v-model="sshVisible"
        title="ssh登录"
        :before-close="handleCloseSSH"
        width="410px"
        :close-on-click-modal="false"
      >
        <el-form ref="sshForm" :model="sshForm" :rules="rules" label-width="70px">
          <el-form-item prop="SSHaccount" label="账号">
            <el-input v-model="sshForm.SSHaccount" placeholder="请输入账号" />
          </el-form-item>
          <el-form-item prop="SSHpassword" label="密码">
            <el-input v-model="sshForm.SSHpassword" placeholder="请输入密码" show-password />
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="handleCloseSSH">取消</el-button>
          <el-button type="primary" :loading="submitLoading" @click="handleSSHSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="control-nav">
      <el-button type="primary" @click="addPortMap"> 端口映射管理 </el-button>
      <el-button type="success" @click="refresh"> 刷新 </el-button>
    </div>
    <el-table
      ref="table"
      v-loading="tableLoading"
      :data="tableData"
      style="width: 100%"
      row-class-name="table-row"
      cell-class-name="table-cell"
      header-row-class-name="table-header"
      header-cell-class-name="table-header-cell"
      highlight-selection-row
    >
      <el-table-column prop="hostname" label="名称">
        <template #default="{ row }">
          <span style="color: #2385ff; cursor: pointer" @click="goDetail(row)">
            {{ row.hostname }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="osystem" label="系统版本" min-width="100">
        <template #default="{ row }">
          <span v-show="row.osystem">{{ row.osystem }}</span>
          <span v-show="!row.osystem">--</span>
        </template>
      </el-table-column>

      <el-table-column prop="statusName" label="机器状态" min-width="170">
        <template #default="{ row }">
          <span>
            {{ row.statusName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="tagNames" label="规格" width="180">
        <template #default="{ row }">
          <el-tag v-for="tag in row.tagNames" :key="tag" type="info">{{ tag }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="memory" label="内存">
        <template #default="{ row }">
          {{ row.memory ? Math.floor(row.memory / 1024) : 0 }}G
        </template>
      </el-table-column>
      <el-table-column prop="powerState" label="电源状态" min-width="100">
        <template #default="{ row }">
          <div>
            <i
              class="circle"
              :style="{
                background:
                  row.powerState === 'off'
                    ? '#FF2323'
                    : row.powerState === 'on'
                      ? '#15A675'
                      : '#FF2323',
              }"
            />
            <span
              :class="
                row.powerState === 'off'
                  ? 'anomaly-text'
                  : row.powerState === 'on'
                    ? 'end-text'
                    : 'warning-text'
              "
            >
              {{
                row.powerState === "off"
                  ? "关机"
                  : row.powerState === "on"
                    ? "开机"
                    : "未知"
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template #default="scope">
          <div class="table-nav">
            <el-dropdown>
              <span class="el-dropdown-link">
                操作
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="handleRelease(scope.row)">释放实例</el-dropdown-item>
                  <el-dropdown-item @click="handleSSH(scope.row)">ssh私钥登录</el-dropdown-item>
                  <el-dropdown-item @click="handleSSHPassword(scope.row)">ssh账户登录</el-dropdown-item>
                  <el-dropdown-item @click="handleRestPassword(scope.row)">重置密码</el-dropdown-item>
                  <el-dropdown-item @click="handleAddPortMap(scope.row)">添加端口映射</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="pageNum"
      :page-sizes="[10, 50, 100, 500]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  queryAccountMachine,
  liberateMachine,
  AddPortMapping,
  getResetPasswordCode,
  resetPasswords
} from '@/api/bareMetal'
import { ElMessage, ElMessageBox } from 'element-plus'
import BareMetalDetail from '@/components/BareMetalDetail.vue'
const Base64 = require('js-base64').Base64

export default {
  name: 'BareMetal',
  components: {
    BareMetalDetail
  },
  data() {
    return {
      data: [],
      tableLoading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      systemId: '',
      detailVisible: false,
      form: {
        name: '',
        ip: '',
        port: '',
        protocol: '',
        systemID: '',
        protocolOptions: ['tcp', 'udp']
      },
      portMapVisible: false,
      rules: {
        port: [
          { required: true, message: '请输入端口号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入端口号'))
              }
              // 只能是正整数，且不能带小数点
              if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error('端口号请输入正整数'))
              } else if (Number(value) < 1 || Number(value) > 65535) {
                callback(new Error('端口号范围1-65535'))
              } else {
                callback()
              }
            },

            trigger: 'blur'
          }
        ],
        protocol: [
          { required: true, message: '请选择协议', trigger: 'blur' }
        ],
        phoneCode: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value.trim() === '') {
                callback(new Error('请再次输入密码'))
              } else if (value.trim() !== this.resetPasswordForm.password.trim()) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            }
          }],
        machineName: [
          { required: true, message: '请输入释放的实例名称', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value.trim() === '') {
                callback(new Error('请输入释放的实例名称'))
              } else if (value.trim() !== this.releaseForm.correctMachineName.trim()) {
                callback(new Error('请输入正确的实例名称'))
              } else {
                callback()
              }
            }
          }
        ],
        isRelease: [
          { required: true, message: '请选择是否释放实例', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value === false) {
                callback(new Error('请选择是否释放实例'))
              } else {
                callback()
              }
            }
          }
        ],
        SSHaccount: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        SSHpassword: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      resetPasswordForm: {
        password: '',
        confirmPassword: '',
        phoneCode: '',
        account: 'root'
      },
      resetPasswordVisible: false,
      submitLoading: false,
      isDisabledPsd: false,
      buttonNamePsd: '发送验证码',
      CountDownPsd: 60, // 倒计时,
      row: null,
      releaseForm: {
        // 正确机器名称
        correctMachineName: '',
        machineName: '',
        isRelease: false,
        systemID: ''
      },
      releaseVisible: false,
      sshForm: {
        SSHaccount: '',
        SSHpassword: '',
        host: '',
        port: 22,
        username: ''
      },
      sshVisible: false
    }
  },
  computed: {
    tableData() {
      const startIndex = (this.pageNum - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
      return this.data.slice(startIndex, endIndex)
    },
    totalPages() {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  created() {
    // 实例列表
    this.getServerList()
  },
  methods: {
    goDetail(row) {
      this.systemId = row.systemId
      this.detailVisible = true
    },
    handleCloseDetail() {
      this.detailVisible = false
    },
    getServerList() {
      this.tableLoading = true
      queryAccountMachine().then((res) => {
        if (res.meta.status === 200) {
          this.data = res.data
          this.total = res.data.length
          this.totalPage()
        } else {
          ElMessage.error(res.meta.msg)
        }
        this.tableLoading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.totalPage()
    },
    handleCurrentChange(val) {
      this.pageNum = val
    },
    // 计算总页数，确保当前页不大于总页数
    totalPage() {
      const totalPage = Math.ceil(this.total / this.pageSize)
      this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
      this.pageNum = this.pageNum < 1 ? 1 : this.pageNum
    },
    /**
     * 刷新
     */
    refresh() {
      this.pageNum = 1
      this.pageSize = 10
      this.getServerList()
    },
    /** 重置密码 */
    handleRestPassword(row) {
      if (row.statusName !== 'Deployed') {
        return ElMessage.warning('请先部署机器后再重置密码！')
      }
      this.resetPasswordVisible = true
      this.row = row
    },
    handleCloseResetPassword() {
      this.resetPasswordVisible = false
      this.$refs.resetPasswordForm.resetFields()
      this.resetPasswordForm.password = ''
      this.resetPasswordForm.confirmPassword = ''
      this.resetPasswordForm.phoneCode = ''
    },
    handleResetPasswordSubmit() {
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const data = {
            host: this.row.ipAddresses[0],
            password: this.resetPasswordForm.password,
            username: this.row.osystem,
            smsCode: this.resetPasswordForm.phoneCode
          }
          resetPasswords(data).then((res) => {
            if (res.meta.status === 200) {
              ElMessage.success('密码重置成功')
              this.handleCloseResetPassword()
            } else {
              ElMessage.error(res.meta.msg)
            }
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    /**
     * 添加端口映射
     */
    handleAddPortMap(row) {
      if (!row.publicPortSSH) {
        this.$message.warn('该机器未启用公网ssh，不能做端口映射，如有需要，请联系管理员！')
        return
      }
      this.portMapVisible = true
      this.form.hostname = row.hostname
      this.form.ip = row.ipAddresses[0]
      this.form.systemID = row.systemId
    },
    handleClosePortMap() {
      this.portMapVisible = false
      this.$refs.form.resetFields()
      this.form.port = ''
      this.form.protocol = ''
    },
    handleAddPortMapSubmit() {
      if (!this.form.ip) {
        ElMessage.error('该机器IP为空，无法添加端口映射')
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const data = {
            privateIp: this.form.ip,
            privatePort: this.form.port,
            protocol: this.form.protocol,
            systemID: this.form.systemID
          }
          AddPortMapping(data).then((res) => {
            if (res.meta.status === 201) {
              ElMessage.success(res.meta.msg)
              this.handleClosePortMap()
              this.getServerList()
            } else {
              ElMessage.error(res.meta.msg)
            }
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    addPortMap() {
      this.$store.dispatch('core/openApp', 8889162036467460)
    },
    /**
     * 释放机器
     */
    handleRelease(row) {
      this.releaseVisible = true

      this.releaseForm.systemID = row.systemId
      this.releaseForm.correctMachineName = row.hostname
      this.$nextTick(this.$refs.releaseForm.resetFields)
    },
    handleReleaseSubmit() {
      this.$refs.releaseForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          liberateMachine(this.releaseForm.systemID).then((res) => {
            if (res.meta.status === 200) {
              this.$message.success('释放成功')
              const totalPage = Math.ceil((this.total - 1) / this.pageSize)
              this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
              this.pageNum = this.pageNum < 1 ? 1 : this.pageNum
              this.getServerList()
              this.handleCloseRelease()
            } else {
              ElMessage.error(res.meta.msg)
            }
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    handleCloseRelease() {
      this.releaseVisible = false
      this.releaseForm.machineName = ''
      this.releaseForm.isRelease = false
      this.$refs.releaseForm.resetFields()
    },
    /** ssh私钥登录 */
    handleSSH(row) {
      const terminalBase64 = Base64.encode(JSON.stringify({
        host: row.ipAddresses[0],
        port: 22,
        username: row.osystem
      }))
      this.$store.commit('core/BARE_SSH_LOGIN_TYPE', 'PublicKey')
      this.$store.commit('core/BARE_SSH_BASE64', terminalBase64)
      this.$store.dispatch('core/openApp', 8889162036467459)
    },
    handleCloseSSH() {
      this.sshVisible = false
      this.$refs.sshForm.resetFields()
      this.sshForm.SSHaccount = ''
      this.sshForm.SSHpassword = ''
    },
    handleSSHSubmit() {
      this.$refs.sshForm.validate((valid) => {
        if (valid) {
          const terminalBase64 = Base64.encode(JSON.stringify({
            host: this.sshForm.host,
            port: this.sshForm.port,
            username: this.sshForm.SSHaccount,
            password: this.sshForm.SSHpassword
          }))
          this.$store.commit('core/BARE_SSH_BASE64', terminalBase64)
          this.$store.dispatch('core/openApp', 8889162036467459)
          this.handleCloseSSH()
        }
      })
    },
    handleSSHPassword(row) {
      this.sshVisible = true
      this.$store.commit('core/BARE_SSH_LOGIN_TYPE', 'Password')
      this.sshForm.host = row.ipAddresses[0]
      this.sshForm.port = 22
      this.sshForm.username = row.osystem
    },
    // 获取验证码密码
    sendMsgPsdHandle() {
      // 先把按钮禁止，并进行倒计时，防止网速慢的用户重复点击
      this.isDisabledPsd = true
      this.timerPsd = setInterval(() => {
        this.CountDownPsd--
        this.buttonNamePsd = this.CountDownPsd + '秒后重试'
        if (this.CountDownPsd <= 0) {
          clearInterval(this.timerPsd)
          this.buttonNamePsd = '发送验证码'
          this.CountDownPsd = 60
          this.isDisabledPsd = false
        }
      }, 1000)
      // 操作验证发送API BLOCK
      getResetPasswordCode().then((res) => {
        if (res.meta.status === 200) {
          ElMessage.success('发送成功')
        } else {
          ElMessage.error(res.meta.msg)
        }
      }).catch(() => {
        // 若接口请求错误，把倒计时关了，按钮恢复可点击状态
        clearInterval(this.timerPsd)
        this.buttonNamePsd = '发送验证码'
        this.CountDownPsd = 60
        this.isDisabledPsd = false
      })
    }
  }
}
</script>

<style lang="less">
.card-view {
  padding: 24px 30px;
  .el-button--success{
    background-color: #fff !important;
    color: #437AEC !important;
    border: 1px solid #437AEC !important;
  }
  .phone-code-btn{
    .el-input__suffix{
      top:5px;
      right: -10px;
      color: #437AEC !important;
      &::before{
        content: "";
        display: inline-block;
        width: 1px;
        height: 18px;
        background: #DCDCDC;
        position: absolute;
        left:10px;
        top: 3px;
      }
    }
  }
  .ssh-dialog{
    .el-dialog__body{
      padding: 0;
    }
  }
  .el-tag{
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 22px;
}
  .el-icon{
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .el-dialog {
    max-height: 80vh !important;
    .el-dialog__body {
      max-height: 80vh !important;
    }
    .el-icon{
      font-size: 20px !important;
    }
  }
  .control-nav{
    display: flex;
  }
  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .normal-text {
    color: #1677ff;
  }
  .anomaly-text {
    color: #ff2323;
  }
  .warning-text {
    color: #fd8e3f;
  }
  .end-text {
    color: #15a675;
  }
  .el-table {
    margin-bottom: 16px;
  }
  .table-row {
    background-color: #fff !important;
  }
  .el-table .cell {
    line-height: 24px !important;
    color: #303133 !important;
    font-weight: 400;
    text-align: center;
  }
  .table-header-cell {
    font-weight: 400;
    font-size: 16px;
    color: #303133;
    line-height: 24px;
    border: none !important;
  }
  .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
    background-color: #437AEC !important;
  }
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    background: #fff !important;
    border: 1px solid #d9d9d9 !important;
  }
  .el-pagination.is-background .btn-next:disabled, .el-pagination.is-background .btn-prev:disabled{
    background: #cccdce !important;
  }
  .el-dialog{
    .el-button--default {
      background-color: #F2F3F5!important;
      border: none !important;
      color: #4E5969 !important;
    }
    .el-button--primary {
      background-color: #437AEC !important;
      border: 1px solid #437AEC !important;
      color: #fff !important;
    }
  }

  .el-button--primary {
    background-color: #437AEC !important;
    border: 1px solid #437AEC !important;
    color: #fff !important;
  }
  .el-button--text{
    border: none !important;
    background-color: transparent !important;
  }
  .selection-row {
    td {
      background-color: #e0e5f0 !important;
    }
  }
  .el-dialog__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .el-dialog__title {
      font-weight: 500;
      font-size: 18px;
      color: #303133;
      line-height: 25px;
    }
  }
  .el-form-item__label {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  .control-nav {
    margin-bottom: 16px;
  }
  .detail-dialog{
    .el-dialog__body , .el-dialog__footer{
      background-color: #f4f7fe;
    }
  }
  .el-table .cell:has(> .el-tag){
    overflow:visible
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    ::v-deep .el-button--primary {
      background-color: #437AEC !important;
    }
  }
  .el-select-dropdown{
    max-width: 300px !important;
  }
  .el-select{
    width: 100%;
  }
}

</style>
