<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

const animationDuration = 6000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          recvData: [],
          sentData: []
        }
      }
    },
    xAxisData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    echatsOption() {
      return {
        chartData: this.chartData,
        XAxisData: this.xAxisData
      }
    }
  },
  watch: {
    echatsOption: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: '#FDFEFF',
          borderWidth: 0,
          borderColor: '#FDFEFF',
          padding: [0, 0, 0, 0],
          formatter: function(params) {
            return `
            <div class="echarts-container-box">
              <div class="time">
              ${params[0].axisValue}
              </div>
              <div class="params">
                <span class="circle" style="background:${params[0].color}"></span>
                ${params[0].seriesName} 
                <span class="value">${params[0].data}字节/秒</span>
                <span class="unit"></span>
              </div>
              <div class="params">
                <span class="circle" style="background:${params[1].color}"></span>
                ${params[1].seriesName} 
                <span class="value">${params[1].data}字节/秒</span>
                <span class="unit"></span>
              </div>
          </div>`
          }
        },
        grid: {
          left: 30,
          right: 40,
          bottom: 20,
          top: 60,
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.xAxisData || [],
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#4e5960'
          }
        }],
        yAxis: [{
          name: '网络接收和发送速率',
          nameTextStyle: {
            color: '#000',
            fontSize: 16,
            fontWeight: 'bold',
            align: 'left'
          },
          nameGap: 30,
          type: 'value',
          axisTick: {
            show: false
          },
          splitArea: {
            show: false
          },
          axisLabel: {
            color: '#4e5960'
          }

        }],
        series: [
          {
            name: '网络接收',
            type: 'bar',
            barWidth: 14,
            data: this.chartData.recvData || [],
            itemStyle: {
              color: '#165DFF'
            },
            animationDuration
          }, {
            name: '发送速率',
            type: 'bar',
            barWidth: 14,
            data: this.chartData.sentData || [],
            animationDuration,
            itemStyle: {
              color: '#F7BA1E'
            }
          }
        ]
      })
    }
  }
}
</script>
