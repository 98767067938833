<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true,
      default: () => []
    },
    // eslint-disable-next-line vue/prop-name-casing
    XAxisData: {
      type: Array,
      required: true,
      default: () => []
    },
    name: {
      type: String,
      required: true,
      default: ''
    },
    color: {
      type: String,
      default: 'red'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    echatsOption() {
      return {
        chartData: this.chartData,
        XAxisData: this.XAxisData
      }
    }
  },
  watch: {
    echatsOption: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeUnmount() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions()
    },
    setOptions() {
      if (!this.chart) {
        return
      }
      this.chart.setOption({
        xAxis: {
          data: this.echatsOption.XAxisData,
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#4e5960'
          },
          axisLine: {
            show: false
          }

        },
        grid: {
          left: 30,
          right: 40,
          bottom: 20,
          top: 60,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'none'
          },
          backgroundColor: '#FDFEFF',
          borderWidth: 0,
          borderColor: '#FDFEFF',
          padding: [0, 0, 0, 0],
          formatter: function(params) {
            return `
            <div class="echarts-container-box">
              <div class="time">
              ${params[0].axisValue}
              </div>
              <div class="params">
                <span class="circle" style="background:${params[0].color}"></span>
                ${params[0].seriesName} 
                <span class="value">${params[0].data}</span>
                <span class="unit">%</span>
              </div>
          </div>`
          }

        },
        yAxis: {
          name: this.name,
          nameTextStyle: {
            color: '#000',
            fontSize: 16,
            fontWeight: 'bold'
          },
          nameGap: 30,
          type: 'value',
          splitNumber: 5,
          max: 100,
          min: 0,
          splitLine: {
            lineStyle: {
              type: 'solid'
            }
          },
          axisLabel: {
            formatter: '{value} %',
            color: '#909399',
            fontSize: 14
          },
          splitArea: {
            show: false
          }
        },
        legend: {
          show: false
        },
        series: [{
          name: this.name,
          symbol: 'none',
          itemStyle: {
            color: this.color,
            lineStyle: {
              width: 2
            }

          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: this.hexToRgb(this.color, 0.4)
              },
              {
                offset: 1,
                color: this.hexToRgb(this.color, 0.1)
              }
            ])
          },
          smooth: true,
          type: 'line',
          data: this.echatsOption.chartData,
          animationDuration: 2800,
          animationEasing: 'cubicInOut'
        }
        ]
      })
    },
    hexToRgb(hex, opacity) {
      // 去掉十六进制字符串前面的 '#' 符号
      hex = hex.replace('#', '')
      // 检查是否为 3 位的缩写形式，如果是则转换为 6 位
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      // 拆分红、绿、蓝三个颜色通道
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      // 返回 RGB 对象或字符串（根据需要调整）
      // return { r, g, b }; // 返回对象
      return `rgb(${r}, ${g}, ${b},${opacity})` // 返回字符串
    }
  }
}
</script>
<style  lang="less">
  .echarts-container-box {
    width: 236px;
    // height: 78px;
    background: linear-gradient( 321deg, #FDFEFF 0%, #F4F7FC 100%);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid;
    border-image: linear-gradient(337deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    padding:  10px 8px;
    font-family: PingFang SC, PingFang SC;
  }
  .circle{
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #722ED1;
    border-radius: 50%;
    margin-right: 6px;
  }
  .time {
    font-weight: 500;
    font-size: 12px;
    color: #1D2129;
    line-height: 20px;
    margin-bottom: 6px;
  }
  .params{
    background-color: #fff;
    padding: 6px 8px;
    box-shadow: 6px 0px 20px 0px rgba(34,87,188,0.1);
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    font-size: 12px;
    color: #4E5969;
    line-height: 20px;
    // margin-bottom: 6px;
  }
  .value{
    font-weight: 500;
    font-size: 16px;
    color: #1D2129;
    line-height: 24px;
  }
  .unit{
    color: #1D2129;
  }
</style>
